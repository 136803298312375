import React from "react";
import "./sticky.scss";
import { stickyCallIcon } from "../../images";

const Sticky = () => {
    return (
        <>
        <section className="sticky_sec1">
            <a href="tel:+254709603001" className="sticky_contianer">
                <img src={stickyCallIcon} alt="call Icon" className="sticky_img" />
            </a>
        </section>
        </>
    );
};

export default Sticky;

