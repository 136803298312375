import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./Mainheader.scss";
import { catalogpdf, newlogo } from "../../images";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import MobileMenu from "../MobileMenu/MobileMenu";
// const body = document.querySelector("body");

const Mainheader = ({ products }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const { width } = useWindowDimensions();
  const [menuItemActiveIndex, setMenuItemActiveIndex] = useState(null);
  const [isBg, setIsbg] = useState(false);

  useEffect(() => {
    // body.style.overflow = "auto";
    if (window.location.pathname === "/") {
      setIsbg(false);
    } else {
      setIsbg(true);
    }
    setOpenMenu(false);
  }, [window.location.pathname]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  });

  const handleClick = () => {
    if (!openMenu) {
      // Disable scroll
      // body.style.overflow = "hidden";
    } else {
      // Enable scroll
      // body.style.overflow = "auto";
    }
    if (width <= 834) {
      setIsbg(!isBg);
    }
    setOpenMenu(!openMenu);
  };

  const handleScroll = () => {
    if (window.location.pathname === "/")
      if (window.pageYOffset > 0) {
        setIsbg(true);
      } else {
        setIsbg(false);
      }
  };

  const handlePlusClick = (i) => {
    setMenuItemActiveIndex(i);
  };

  let mobileProducts = [];
  products?.forEach((item, i) => {
    item.key = i;
    item.redirectLink = "/our-products/" + item.slug;
    item.subLinkName = item.name;
    mobileProducts.push(item);
  });

  const mbMenuArr = [
    {
      isLink: true,
      redirectLink: "/about-us",
      linkName: "About Us",
    },
    {
      isLink: true,
      linkName: "Our Products",
      subMenu: mobileProducts,
      redirectLink: "/our-products/catering-food-preparation",
    },
    // {
    //   isLink: true,
    //   redirectLink: "/blogs",
    //   linkName: "Blogs",
    // },
    {
      isLink: true,
      redirectLink: "/contact-us",
      linkName: "Contact",
    },
  ];

  const mbMenuList = mbMenuArr
    ? mbMenuArr.map((mbSingleMenu, i) => (
        <React.Fragment key={i}>
          <MobileMenu
            mbSingleMenu={mbSingleMenu}
            menuItemIndex={i + 1}
            handlePlusClick={handlePlusClick}
            menuItemActiveIndex={menuItemActiveIndex}
          />
        </React.Fragment>
      ))
    : null;

  return (
    <>
      <header className={`header ${isBg ? "navbar_bg" : null}`}>
        {width > 600 ? (
          <div className="my_container">
            <div className="Navbarclass">
              <NavLink to="/#">
                <div className="logo">
                  <img
                    src={newlogo}
                    className="nav_logo_img"
                    alt="caterina logo img"
                  />
                </div>
              </NavLink>

              <ul>
                <li>
                  <NavLink
                    to="/about-us"
                    activeClassName="link_selected"
                    className="route_link"
                    exact
                  >
                    About Us
                  </NavLink>
                </li>
                <li className="nav-item dropdown">
                  <a
                    href="/our-products/catering-food-preparation"
                    className="nav-link dropdown-toggle ourprod_link"
                    // aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Our Products
                  </a>
                  <div className="dropdown-menu">
                    {products &&
                      products.map((item, i) => (
                        <React.Fragment key={i}>
                          <div>
                            <NavLink
                              to={`/our-products/${item.slug}`}
                              activeClassName="link_selected"
                              className="route_link_black dropdown-item"
                              exact
                            >
                              {item.name}
                            </NavLink>
                          </div>
                        </React.Fragment>
                      ))}
                  </div>
                </li>
                {/* <li>
                  <NavLink
                    to="/Blogs"
                    activeClassName="link_selected"
                    className="route_link"
                    exact
                  >
                    Blogs
                  </NavLink>
                </li> */}
                <li>
                  <NavLink
                    to="/contact-us"
                    activeClassName="link_selected"
                    className="route_link"
                    exact
                  >
                    Contact
                  </NavLink>
                </li>
                <li>
                  <button className="catalogue_btn">
                    <a
                      href={catalogpdf}
                      activeClassName="link_selected"
                      className="route_link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Catalogue
                    </a>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div className="my_container">
            <div className="mb_nav_flex">
              <NavLink to="/#">
                <img
                  src={newlogo}
                  alt="Caterina logo mobile"
                  loading="lazy"
                  className="mb_nav_logo_img"
                />
              </NavLink>

              <div
                className={`nav_icon ${openMenu ? "open_nav" : ""}`}
                onClick={handleClick}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className={`mb_menu ${openMenu ? "mb_menu_active" : null}`}>
              <div className="mb_links_wrapper_cont">
                {mbMenuList}
                <button className="catalogue_btn">
                  <a
                    href={catalogpdf}
                    activeClassName="link_selected"
                    className="route_link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Catalogue
                  </a>
                </button>
              </div>
            </div>
          </div>
        )}
      </header>
    </>
  );
};

export default Mainheader;
