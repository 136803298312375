import React from "react";
import { Redirect } from "react-router-dom";

const redirectMapping = [
  {
    from: "/blog/direct-sales-associate/",
    to: "/",
  },
  {
    from: "/blog/4-factors-know-nri-home-loan/",
    to: "/",
  },
  {
    from: "/blog/how-to-leverage-business-analytics-when-applying-for-a-loan/",
    to: "/",
  },
  {
    from: "/blog/avoid-loan-property-rejection/",
    to: "/",
  },
  {
    from: "/blog/introducing-new-finance-super-hero-credit-card/",
    to: "/",
  },
  {
    from: "/blog/how-to-get-a-business-loan-for-your-startup-in-india/",
    to: "/",
  },
  {
    from: "/blog/personal-loan-101-self-employed-professionals/",
    to: "/",
  },
  {
    from: "/blog/what-credit-score-do-i-need-to-get-a-business-loan/",
    to: "/",
  },
  {
    from: "/blog/car-loan/",
    to: "/",
  },
  {
    from: "/blog/using-business-loans-for-expansion-vs-operational-costs-a-comparative-analysis%EF%BF%BC/",
    to: "/",
  },
  {
    from: "/blog/how-to-get-a-business-vehicle-loan/",
    to: "/",
  },
  {
    from: "/blog/business-loan-kolhapur/",
    to: "/",
  },
  {
    from: "/blog/strategic-benefits-of-early-loan-repayments-for-businesses/",
    to: "/",
  },
  {
    from: "/blog/business-loan/",
    to: "/",
  },
  {
    from: "/blog/other/",
    to: "/",
  },
  {
    from: "/blog/refer-n-earn/",
    to: "/",
  },
  {
    from: "/blog/get-up-to-200-percentage-as-used-car-loan/",
    to: "/",
  },
  {
    from: "/blog/unlock-your-loan-agent-success-with-ruloans-a-comprehensive-guide/",
    to: "/",
  },
  {
    from: "/blog/your-ultimate-guide-to-generating-leads-as-a-dsa-agent/",
    to: "/",
  },
  {
    from: "/blog/essentials-of-new-car-loan/",
    to: "/",
  },
  {
    from: "/blog/how-used-car-loan-size-has-been-on-the-rise-in-pandemic-years/",
    to: "/",
  },
  {
    from: "/blog/home-loan/",
    to: "/",
  },
  {
    from: "/blog/personal-loan/",
    to: "/",
  },
  {
    from: "/blog/financing-solutions-for-seasonal-businesses-overcoming-off-peak-challenges/",
    to: "/",
  },
  {
    from: "/blog/become-a-ceo-of-your-own-company/",
    to: "/",
  },
  {
    from: "/blog/7-most-common-car-loan-mistakes-to-avoid/",
    to: "/",
  },
  {
    from: "/blog/unlocking-lucrative-opportunities-tax-consultants-guide-to-thriving-with-ruloans/",
    to: "/",
  },
  {
    from: "/blog/which-auto-loan-is-best/which-auto-loan-is-best/",
    to: "/",
  },
  {
    from: "/blog/advantages-of-loan-dsa-agents-in-india/",
    to: "/",
  },
  {
    from: "/blog/new-car-loan-questions-that-you-must-intrigued/",
    to: "/",
  },
  {
    from: "/blog/news-archives/",
    to: "/",
  },
  {
    from: "/blog/credit-card/",
    to: "/",
  },
  {
    from: "/blog/how-to-pic-best-car-loan/",
    to: "/",
  },
  {
    from: "/blog/the-impact-of-global-economic-trends-on-business-loan-availability/",
    to: "/",
  },
  {
    from: "/blog/6-easy-steps-to-become-a-loan-dsa-with-ruloans/",
    to: "/",
  },
  {
    from: "/blog/benefits-of-being-a-dsa/",
    to: "/",
  },
  {
    from: "/blog/dsa/",
    to: "/",
  },
  {
    from: "/blog/gold-loan/",
    to: "/",
  },
  {
    from: "/blog/debunking-3-myths-about-used-car-loans/",
    to: "/",
  },
  {
    from: "/blog/moratorium/",
    to: "/",
  },
  {
    from: "/blog/lap/",
    to: "/",
  },
  {
    from: "/blog/dsa-loan-partner-program-for-personal-loan/",
    to: "/",
  },
  {
    from: "/blog/unlock-your-potential-as-a-dsa-loan-agent-with-ruloans/",
    to: "/",
  },
  {
    from: "/blog/knowledge-center/",
    to: "/",
  },
  {
    from: "/blog/used-car-loan-interest-rates-in-india-complete-guide/",
    to: "/",
  },
];

const Redirection = () => {
  return (
    <>
      {redirectMapping.map((route, i) => (
        <Redirect key={i} form={route.from} to={route.to} />
      ))}
    </>
  );
};

export default Redirection;
