import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { formimg, arrowright, dropddownicon, sec2back } from "../../images";
import http from "../../helpers/http";
import config from "../../config/config";
import Joi from "joi";
const Form = (props) => {
  // console.log(props);

  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [codes, setCodes] = useState([]);
  const [countryCode, setcountryCode] = useState("+254");

  let base_url = config.api_url + "forms/product";

  let history = useHistory();

  const schema = Joi.object({
    name: Joi.string().trim().required(),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
    phone: Joi.number().required(),
    category: Joi.string().required(),

    details: Joi.string().trim().required(),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();
    // console.log(values);
    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    const { data } = await http.post(base_url, {
      ...values,
      phone: `${countryCode}${values.phone}`,
      product_name: props.product,
    });

    if (data) {
      // redirect
      // navigate("/thank-you");
      // <Redirect to="/thank-you" />;
      history.push("/thank-you");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    if (e.target.type == "checkbox") {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.checked,
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  const fetchCodes = async () => {
    const { data } = await http.get(`${config.api_url}countries/codes`);
    // console.log(data);
    if (data) {
      setCodes(data);
    }
  };

  useEffect(() => {
    fetchCodes();
  }, []);

  return (
    <>
      <section className="proinner_sec3" data-aos="fade-up">
        <div className="my_container">
          <div className="row">
            <div className="col-lg-5 background order-lg-2">
              <img
                className="form_backimg"
                src={sec2back}
                alt="caterina form back img"
              />
              <img className="form_img" src={formimg} alt="caterina form img" />
            </div>

            <div className="col-lg-7 order-lg-1" id="proinner_form">
              <div className="text_container">
                <h2 className="form_heading">Get In Touch</h2>
                <p className="form_desc">
                  Caterina is a brand of commercial kitchen and cleaning
                  appliances that has serviced the East African market for close
                  to two decades. Owned by Hypermart LTD
                </p>
              </div>
              <div className="form_container">
                <form className="form-group" onSubmit={handleSubmit}>
                  <div className="material-textfield">
                    <input
                      className="form_input"
                      placeholder=" "
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                    <label>Name*</label>
                  </div>
                  <div className="error">{formError.name}</div>
                  <div className="row">
                    <div className="col-md-6 col-lg-6 no_padding">
                      <div className="material-textfield">
                        <div className="mobile_no_flex">
                          <div className="cc_box">
                            <select
                              className="cc_dd"
                              name="countryCode"
                              id="countryCode"
                              value={countryCode}
                              onChange={(e) => setcountryCode(e.target.value)}
                            >
                              {codes.map((item, i) => {
                                return (
                                  <option value={`+${item.phonecode}`} key={i}>
                                    {`+${item.phonecode}`}
                                  </option>
                                );
                              })}
                            </select>
                            <img
                              className="dropwdown_icon"
                              width={9}
                              height={8}
                              loading="lazy"
                              alt="dropdown icon"
                              src={dropddownicon}
                            />
                          </div>
                          <div className="no_box">
                            <input
                              className="form_input"
                              placeholder=" "
                              type="tel"
                              name="phone"
                              value={values.phone}
                              onChange={handleChange}
                            />
                            <label>Phone Number*</label>
                          </div>
                          <div className="error">{formError.phone}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 no_padding">
                      <div className="material-textfield">
                        <input
                          className="form_input"
                          placeholder=" "
                          type="text"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                        <label>E-mail ID*</label>
                        <div className="error">{formError.email}</div>
                      </div>
                    </div>
                  </div>
                  <div className="material-textfield">
                    <select
                      className="input form_input select_input"
                      name="category"
                      onChange={handleChange}
                    >
                      <option defaultValue value="">
                        Select Category*
                      </option>
                      {/* <option value="demo1" className="demo1 active" disabled>
                      demo1
                      </option> */}
                      <option value="Commercial Ovens" className="active">
                        Commercial Ovens
                      </option>
                      <option value="Cleaning">Cleaning</option>
                      <option value="Refrigeration">Refrigeration</option>
                      <option value="Fryers">Fryers</option>
                      <option value="Baking">Baking</option>
                      {/* <option value="Commercial Ovens">Commercial Ovens</option> */}
                      <option value="Cleaning & Laundry">
                        Cleaning & Laundry
                      </option>
                      <option value="Service Center">
                          Service Center
                        </option>
                      <option value="Other">Other</option>
                    </select>
                    <div className="error">{formError.category}</div>
                  </div>
                  {/* <div className="material-textfield">
                    <input
                      className="form_input"
                      placeholder=" "
                      type="text"
                      name="details"
                      value={values.details}
                      onChange={handleChange}
                    />
                    <label>Enquiry Details*</label>
                    <div className="error">{formError.details}</div>
                  </div> */}
                  <div className="material-textfield">
                    <textarea
                      rows="4"
                      className="form_input textarea_text"
                      placeholder="Enquiry Details*"
                      type="text"
                      name="details"
                      value={values.details}
                      onChange={handleChange}
                    ></textarea>
                    <div className="error">{formError.details}</div>
                  </div>

                  <div className="form_checkbox">
                    <input
                      type="checkbox"
                      checked={values.consent}
                      onChange={handleChange}
                      name="consent"
                      className="check_box_selector"
                    />
                    <label className="check_box_label">
                      By checking the box, I agree to receive newsletters by
                      emails
                    </label>
                  </div>

                  <div className="cta_wrapper">
                    {/* <button className="send">
                        Send
                        <img src={arrowright} alt="caterina right img" />
                      </button> */}

                    <button className="send" type="submit">
                      {loading ? "Sending.." : "Send"}
                      <img src={arrowright} alt="caterina right img" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Form;
