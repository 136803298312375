import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Mainheader from "./components/MainHeader/Mainheader";
import Newsletter from "./components/Newsletter/Newsletter";
import AboutUs from "./pages/AboutUs/AboutUs";
// import Blogs from "./pages/Blog/Blogs";
// import BlogInner from "./pages/BlogInner/BlogInner";
// import Homepage from "./pages/Homepage/HomepageOld";
import HomepageNew from "./pages/Homepage/Homepage";
import OurProducts from "./pages/OurProducts/OurProducts";
import ProductInner from "./pages/ProductInner/ProductInner";
import ContactUs from "./pages/ContactUs/ContactUs";
import Notfound from "./pages/404/Notfound";
import ThankYou from "./pages/ThankYou/ThankYou";
// import Faqpage from "./components/Faq/Faqpage";
// import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import config from "./config/config";
import http from "./helpers/http";
// import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import { Toaster } from "react-hot-toast";
import Redirection from "./components/Redirection/Redirection";

const DefaultLayout = () => {
  const [products, setproducts] = useState([]);

  const fetchproducts = async () => {
    let url = `${config.api_url}products/categories`;

    const { data } = await http.get(url);
    if (data) {
      // console.log(data);
      setproducts(data);
    }
  };
  useEffect(() => {
    fetchproducts();
  }, []);
  return (
    <>
      <Toaster />
      <Mainheader products={products} />
      <Switch>
        <Route exact path="/" component={HomepageNew} />
        {/* <Route exact path="/v2" component={Homepage} /> */}
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/our-products/:category" component={OurProducts} />
        <Route
          exact
          path="/our-products/:category/:product"
          component={ProductInner}
        />
        {/* <Route exact path="/blogs" component={Blogs} /> */}
        {/* <Route exact path="/blogs/:slug" component={BlogInner} /> */}
        <Route exact path="/contact-us" component={ContactUs} />
        {/* <Route exact path="/faq" component={Faqpage} /> */}
        {/* <Route
          exact
          path="/terms-and-conditions"
          component={TermsAndConditions}
        /> */}
        {/* <Route exact path="/privacy-policy" component={PrivacyPolicy} /> */}
        <Route exact path="/404" component={Notfound} />
        <Route exact path="/thank-you" component={ThankYou} />

        <Redirection />
        <Redirect from="*" to="/404" />
      </Switch>
      <Newsletter />
      <Footer products={products} />
    </>
  );
};

export default DefaultLayout;
