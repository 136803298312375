import React, { useState, useEffect } from "react";
import { Banner } from "../../components/Banner/Banner";
import {
  aboutusbanner,
  aboutusright,
  box,
  icon1,
  arrowright,
  sec2back,
} from "../../images";
import "./aboutus.scss";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";
import Sticky from "../../components/Sticky/Sticky";

const AboutUs = () => {
  const { width } = useWindowDimensions();
  const [rightList, setRightList] = useState(0);

  const tabsArr = [
    {
      title: "Our Values",
    },
    {
      title: "Service Center",
    },
    {
      title: "What We Offer",
    },
  ];

  const rightArr = [
    {
      title: "We believe in investing in quality equipment",
      desc: "From ensuring food is consistent and served on time, to keeping your establishment clean and hygienic, to cope with busy periods, we understand the high standards demanded by the food service industry and tailor our product offering to fulfill your needs. We believe quality, long-lasting equipment should increase the efficiency of your establishment and allow you to focus on activities which your customers truly value.",
      desc2: "",
    },
    {
      title: "Excellent after-sales care",
      desc: "With expert after-sales care and a dedicated team of full-time technicians, you can rest assured your equipment is in good hands. Caterina products come with the ‘sold and serviced by Ramtons’ seal of approval, you can contact us on our toll-free number or visit our service center should you require any technical assistance or spare parts",
      desc2: "",
    },
    {
      title:
        "We offer an extensive range of products to meet your commercial needs",
      desc: "Having served the industry for over 15 years, Caterina has earned a reputation for offering quality, long-lasting equipment that provides the necessary durability to withstand heavy commercial use. We offer an extensive range of products to meet your commercial needs. From commercial fryers and blenders to coffee makers to salamanders. Download our catalogue, give us a call or visit our showroom to explore our product range",
      desc2: "",
    },
  ];

  const leftTabsList = tabsArr.length
    ? tabsArr.map((item, i) => (
        <li
          key={i}
          className={`lefttab ${rightList === i ? "active" : null}`}
          onClick={() => setRightList(i)}
        >
          {item.title}
          <img
            className="arrow_right"
            src={arrowright}
            alt="caterina arrow img"
          />
        </li>
      ))
    : null;

  const mbMainAccList = tabsArr.length
    ? tabsArr.map((item, i) => (
        <>
          <li
            className={`mb_main_li ${rightList === i ? "active" : null}`}
            onClick={() => setRightList(i)}
          >
            {item.title}
            <img
              className="arrow_right"
              src={arrowright}
              alt="caterina arrow img"
            />
          </li>
          <ul
            className={`mb_faq_sub_menu--list_wrapper ${
              rightList === i ? "active" : null
            }`}
          >
            <div className="col-md-7 col-lg-7 rightbox">
              <div className="right_heading">
                {rightArr[rightList]["title"]}
              </div>
              <div className="right_desc">{rightArr[rightList]["desc"]}</div>
              <div className="right_desc2">{rightArr[rightList]["desc2"]}</div>
            </div>
          </ul>
        </>
      ))
    : null;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MetaDecorator
        metaTitle="About Caterina - Your Trusted Source for Commercial Kitchen Equipment"
        metaDesc="Learn about Caterina, your trusted source for high-quality commercial kitchen equipment. Discover our mission, values, and commitment to excellence."
        canonicalLink={window.location.href}
      />
      <Banner
        imgSrc={aboutusbanner}
        breadcrumbWord1="Home"
        breadcrumbWord3="About Us"
        breadcrumbWord4="About Us"
        disabled
        link3={`/about-us`}
      />
      <section className="aboutus_sec1" data-aos="fade-up">
        <div className="my_container">
          <div className="row">
            <div className="col-lg-7">
              <h1 className="hollow_title">About Us</h1>
              <div className="text_container">
                <h2 className="about_heading">
                  Caterina is a <span>brand of commercial </span>kitchen and
                  cleaning appliances
                </h2>
                <p className="about_desc">
                  Caterina was launched by Hypermart Ltd. (the company behind
                  the Ramtons brand of appliances) in 2005 to supply the HORECA
                  (Hotel, restaurant and café) industry with quality commercial
                  catering and cleaning equipment. Having served the industry
                  for over 15 years, the brand has earned a reputation for
                  offering quality, long-lasting equipment that provides the
                  necessary durability to withstand heavy commercial use
                </p>
                <div className="para_img">
                  <img className="desc_img" src={icon1} alt="caterina icon" />
                  <p className="about_desc2">
                  Amongst our hundreds of items, from commercial refrigeration to
                  commercial ovens to food displays and more, you will be sure
                  to find something to suit your needs. Fryers for restaurants,
                  ovens for bakeries
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5 imagecenter">
              <img
                className="aboutus_back"
                src={sec2back}
                alt="caterina back img"
              />
              <div className="imageanimate">
                <img
                  className="aboutus_right"
                  src={aboutusright}
                  alt="caterina front img"
                />
              </div>
              <div className="aboutus_box_content">
                <img
                  className="aboutus_box"
                  src={box}
                  alt="caterina back img"
                />
                <h4 className="aboutus_box_desc">
                  Best Quality
                  <br />
                  Since
                </h4>
                <h2 className="aboutus_box_year">2005</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="aboutus_sec2" data-aos="fade-up">
        <div className="my_container">
          {width > 767 ? (
            <div className="row">
              <div className="col-md-4 col-lg-4 left_section">
                <ul>{leftTabsList}</ul>
              </div>
              <div className="col-md-7 col-lg-8 rightbox">
                <div className="right_heading">
                  {rightArr[rightList]["title"]}
                </div>
                <div className="right_desc">{rightArr[rightList]["desc"]}</div>
                <div className="right_desc2">
                  {rightArr[rightList]["desc2"]}
                </div>
              </div>
            </div>
          ) : (
            <div className="mb_faq" data-aos="fade-up">
              <ul className="mb_main_wrapper">{mbMainAccList}</ul>
            </div>
          )}
        </div>
      </section>
      <Sticky />
    </>
  );
};

export default AboutUs;
