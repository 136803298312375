import React from "react";
import "./Footer.scss";

import useWindowDimensions from "../../hooks/useWindowDimensions";

import {
  logo,
  call,
  newlogo,
  whatsappwhite,
  fb,
  insta,
  scrollup,
  catalogpdf,
} from "../../images";
import { Link, NavLink } from "react-router-dom";

const Footer = ({ products }) => {
  const { width } = useWindowDimensions();
  const today = new Date();

  const scrollHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      {width > 1023 ? (
        <footer className="footer">
          <div className="my_container footer_first_row">
            <div className="row m-0">
              <div className="col-md-5">
                <Link to="/#">
                  <img
                    src={newlogo}
                    alt="caterina footer logo"
                    loading="lazy"
                    className="footer_logo"
                  />
                </Link>
                <div className="footer_contact_detail_box">
                  <div className="footer_contact_title"></div>
                  <div className="footer_contact_desc_address">
                    Caterina is an established brand of commercial kitchen and
                    cleaning equipment
                  </div>
                </div>
                <div className="footer_contact_detail_box">
                  <img className="call_icon" alt="call icon" src={call} />
                  <div className="footer_contact_desc_contact">
                    <span>
                      Call Us : <a href="tel: 0709603001">0709603001 </a>
                    </span>{" "}
                    |{" "}
                    <span>
                      <a href="tel: 0709603100">0709603100 </a>
                    </span>
                    {/* <span className="contact_1">Call Us : <a href="tel: 0709603100" >0709603100 </a></span> */}
                    {/* <br/> Call Us : <a href="tel: +254 709603001">+254 709603001 </a> */}
                  </div>
                </div>
                <div className="footer_contact_detail_box">
                  <img
                    className="whatsapp_icon"
                    alt="whatsapp icon"
                    src={whatsappwhite}
                  />
                  <div className="footer_contact_desc_contact">
                    Whatsapp Us :{" "}
                    <a
                      href="https://wa.me/+254707884461"
                      // href="https://wa.me/+254707884461"
                      target="_blank"
                      rel="noreferrer"
                    >
                      +254707884461
                    </a>
                  </div>
                </div>
                <div className="social_icon">
                  <a
                    className="fb_icon"
                    href="https://www.facebook.com/caterinacommercialappliances/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={fb} alt="caterina fb img" />
                  </a>
                  {/* <a
                    className="insta_icon"
                    href="www.google.com"
                    // target="_blank" rel="noreferrer"
                    // rel="noreferrer"
                  >
                    <img src={insta} alt="caterina insta img" />
                  </a> */}
                </div>
              </div>
              <div className="col-md-5">
                <div className="box_title">Explore</div>
                <div className="box_content_wrapper">
                  {products &&
                    products.map((item, i) => {
                      return (
                        <div className="link_name_wrapper" key={i}>
                          <NavLink
                            to={`/our-products/${item.slug}`}
                            className="link_name"
                            onClick={scrollHandler}
                          >
                            {item.name}
                          </NavLink>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="col-md-2">
                <div className="box_title">Links</div>
                <div className="box_content_wrapper">
                  <div className="link_name_wrapper">
                    <NavLink to="/about-us" className="link_name">
                      About Us
                    </NavLink>
                  </div>

                  <div className="link_name_wrapper">
                    <a
                      href={catalogpdf}
                      className="link_name"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Catalogue
                    </a>
                  </div>

                  {/* <div className="link_name_wrapper">
                    <NavLink to="/blogs" className="link_name">
                      Blogs
                    </NavLink>
                  </div> */}
                  {/* <div className="link_name_wrapper">
                    <NavLink to="/faq" className="link_name">
                      FAQ
                    </NavLink>
                  </div> */}
                  <div className="link_name_wrapper">
                    <NavLink to="/contact-us" className="link_name">
                      Contact Us
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer_second_row">
            <div className="my_container">
              <div className="row m-0 second_row_content">
                <div className="col-lg-6 copyright_text">
                  Caterina © All Rights Reserved - {today.getFullYear()}
                </div>
                <div className="col-lg-6 text-right">
                  {/* <NavLink to="/terms-and-conditions" className="privacy_link">
                    &nbsp;Terms & Condition
                  </NavLink>
                  <div style={{ display: "inline", opacity: "0.8" }}> | </div>
                  <NavLink to="/privacy-policy" className="privacy_link">
                    Privacy Policy
                  </NavLink> */}
                </div>
              </div>
            </div>
          </div>
        </footer>
      ) : (
        <footer className="footer">
          <div className="my_container footer_first_row">
            <div className="row m-0">
              <div className="col-lg-3">
                <img
                  src={logo}
                  alt="Caterina footer logo"
                  loading="lazy"
                  className="footer_logo"
                />
              </div>
              <div className="col-lg-3">
                <div className="footer_contact_details_container">
                  <div className="footer_contact_detail_box">
                    <div className="footer_contact_title"></div>
                    <div className="footer_contact_desc">
                      Caterina is an established brand of commercial kitchen and
                      cleaning equipment
                    </div>
                  </div>
                  <div className="footer_contact_detail_box">
                    <div className="footer_contact_title"></div>
                    <div className="footer_contact_desc numbers_sec">
                      <img className="call_icon" alt="call icon" src={call} />
                      <span>
                        {" "}
                        Call Us : <a href="tel: 0709603001">
                          0709603001
                        </a> | <a href="tel: 0709603100">0709603100 </a>
                      </span>{" "}
                      {/* <br /> */}
                      {/* <span className="contact_1">
                        <a href="tel: 0709603100">0709603100 </a>
                      </span> */}
                    </div>
                    <div className="footer_contact_desc numbers_sec">
                      <img
                        className="call_icon"
                        alt="call icon"
                        src={whatsappwhite}
                      />
                      Whatsapp Us :{" "}
                      <a
                        href="https://wa.me/+254707884461"
                        target="_blank"
                        rel="noreferrer"
                      >
                        +254707884461
                      </a>
                    </div>
                  </div>
                  <div className="footer_contact_detail_box">
                    <div className="box_title">Explore</div>
                    {products &&
                      products.map((item, i) => {
                        return (
                          <div className="link_name_wrapper" key={i}>
                            <NavLink
                              to={`/our-products/${item.slug}`}
                              className="link_name"
                              onClick={scrollHandler}
                            >
                              {item.name}
                            </NavLink>
                          </div>
                        );
                      })}
                    {/* <div className="link_name_wrapper">
                      <NavLink to="/our-products/baking-equipment" className="link_name">
                        Bakery Equipment
                      </NavLink>
                    </div>

                    <div className="link_name_wrapper">
                      <NavLink to="/our-products/butchery-equipment" className="link_name">
                        Butchery Equipment
                      </NavLink>
                    </div>

                    <div className="link_name_wrapper">
                      <NavLink to="/our-products/other" className="link_name">
                        Cleaning Equipment
                      </NavLink>
                    </div>
                    <div className="link_name_wrapper">
                      <NavLink to="/our-products/cooling-and-freezing" className="link_name">
                        Cooling/Freezing Equipment
                      </NavLink>
                    </div>
                    <div className="link_name_wrapper">
                      <NavLink to="/our-products/baking-equipment" className="link_name">
                        Restaurant Equipment
                      </NavLink>
                    </div>
                    <div className="link_name_wrapper">
                      <NavLink to="/our-products/catering-food-preparation" className="link_name">
                        Catering Equipment
                      </NavLink>
                    </div> */}
                  </div>
                  <div className="footer_contact_detail_box">
                    <div className="box_title">Links</div>
                    <div className="link_name_wrapper">
                      <NavLink to="/about-us" className="link_name">
                        About Us
                      </NavLink>
                    </div>

                    <div className="link_name_wrapper">
                      <a
                        href={catalogpdf}
                        className="link_name"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Catalogue
                      </a>
                    </div>

                    {/* <div className="link_name_wrapper">
                      <NavLink to="/blogs" className="link_name">
                        Blogs
                      </NavLink>
                    </div> */}
                    {/* <div className="link_name_wrapper">
                      <NavLink to="/faq" className="link_name">
                        FAQ
                      </NavLink>
                    </div> */}
                    <div className="link_name_wrapper">
                      <NavLink to="/contact-us" className="link_name">
                        Contact Us
                      </NavLink>
                    </div>
                    <div className="social_icon">
                      <a
                        className="fb_icon"
                        href="https://www.facebook.com/caterinacommercialappliances/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={fb} alt="caterina fb img" />
                      </a>
                      {/* <a
                        className="insta_icon"
                        href="www.google.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={insta} alt="caterina insta img" />
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer_second_row">
            <img
              className="uparrow_img"
              src={scrollup}
              alt="caterina arrow"
              loading="lazy"
              onClick={scrollHandler}
            />
            <div className="my_container">
              <div className="row m-0 second_row_content">
                <div className="col-lg-6">
                  <div className="col-lg-6 copyright_text">
                    Caterina © All Rights Reserved - {today.getFullYear()}
                  </div>
                  {/* <NavLink to="/terms-and-conditions" className="privacy_link">
                    &nbsp;Terms & Condition &nbsp;&nbsp;&nbsp;&nbsp;|
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </NavLink>
                  <NavLink to="/privacy-policy" className="privacy_link">
                    Privacy Policy
                  </NavLink> */}
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
