import React, { useEffect, useState } from "react";
import { Banner } from "../../components/Banner/Banner";
import {
  clock,
  contactusleft,
  contactusright,
  location,
  mail,
  phone,
  arrowright,
  contactusleftipad,
  contactusleftmob,
  contactusrightmob,
  dropddownicon,
  contactusban,
  mbcontactusban,
} from "../../images";
import "./ContactUs.scss";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import http from "../../helpers/http";
import config from "../../config/config";
import Joi from "joi";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";
import Sticky from "../../components/Sticky/Sticky";
const ContactUs = (props) => {
  const { width } = useWindowDimensions();

  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [codes, setCodes] = useState([]);
  // const [countryCode, setcountryCode] = useState("+91");
  const [countryCode, setcountryCode] = useState("+254");

  let base_url = config.api_url + "forms/contact";

  const schema = Joi.object({
    name: Joi.string().trim().required(),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
    phone: Joi.number().required(),
    category: Joi.string().required(),

    details: Joi.string().trim().required(),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();
    // console.log(values);
    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    const { data } = await http.post(base_url, {
      ...values,
      phone: `${countryCode}${values.phone}`,
    });

    if (data) {
      // redirect
      // navigate("/thank-you");
      props.history.push("/thank-you");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    if (e.target.type == "checkbox") {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.checked,
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  const fetchCodes = async () => {
    const { data } = await http.get(`${config.api_url}countries/codes`);
    // console.log(data);
    if (data) {
      setCodes(data);
    }
  };

  useEffect(() => {
    fetchCodes();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MetaDecorator
        metaTitle="Contact Us - Caterina"
        metaDesc="Contact us for any inquiries, assistance, or support. We are here to help you with all your commercial kitchen equipment & food preparation needs."
        canonicalLink={window.location.href}
      />
      <Banner
        imgSrc={width > 767 ? contactusban : mbcontactusban}
        breadcrumbWord1="Home"
        breadcrumbWord3="Contact Us"
        breadcrumbWord4="Contact Us"
        disabled
        link3={`/contact-us`}
      />
      <section className="contactus_sec1" data-aos="fade-up">
        <h1 className="hollow_title">Contact</h1>
        <div className="my_container">
          <div className="row adjusted">
            <div className="col-lg-7 right_section order-lg-2">
              <div className="background_img">
                {width > 767 ? (
                  <img src={contactusright} alt="background img" />
                ) : (
                  <img src={contactusrightmob} alt="background img" />
                )}
              </div>
              <div className="text_container">
                <h2 className="contact_head">Get In Touch</h2>
                <p className="contact_desc">
                  Feel free to drop us a line below!
                </p>
                <div className="form_container">
                  <form className="form-group" onSubmit={handleSubmit}>
                    <div className="material-textfield">
                      <input
                        className="form_input"
                        placeholder=" "
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                      />
                      <label>Name*</label>
                    </div>
                    <div className="error">{formError.name}</div>
                    <div className="row">
                      <div className="col-md-6 col-lg-6 no_padding">
                        <div className="material-textfield">
                          <div className="mobile_no_flex">
                            <div className="cc_box">
                              <select
                                className="cc_dd"
                                name="countryCode"
                                id="countryCode"
                                value={countryCode}
                                onChange={(e) => setcountryCode(e.target.value)}
                              >
                                {codes.map((item, i) => {
                                  return (
                                    <option
                                      value={`+${item.phonecode}`}
                                      key={i}
                                    >
                                      {`+${item.phonecode}`}
                                    </option>
                                  );
                                })}
                              </select>
                              <img
                                className="dropwdown_icon"
                                width={9}
                                height={8}
                                loading="lazy"
                                alt="dropdown icon"
                                src={dropddownicon}
                              />
                            </div>
                            <div className="no_box">
                              <input
                                placeholder=" "
                                type="tel"
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                              />
                              <label>Phone Number*</label>
                            </div>
                            <div className="error">{formError.phone}</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-6 no_padding">
                        <div className="material-textfield">
                          <input
                            placeholder=" "
                            type="text"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                          />
                          <label>E-mail ID*</label>
                          <div className="error">{formError.email}</div>
                        </div>
                      </div>
                    </div>
                    <div className="material-textfield">
                      <select
                        className="input select_input"
                        name="category"
                        onChange={handleChange}
                      >
                        <option defaultValue value="">
                          Select Category*
                        </option>
                        {/* <option value="demo1" className="demo1 active">
                          Demo1
                        </option> */}
                        <option value="Commercial Ovens" className="active">
                          Commercial Ovens
                        </option>
                        <option value="Cleaning">Cleaning</option>
                        <option value="Refrigeration">Refrigeration</option>
                        <option value="Fryers">Fryers</option>
                        <option value="Baking">Baking</option>
                        {/* <option value="Commercial Ovens">
                          Commercial Ovens
                        </option> */}
                        <option value="Cleaning & Laundry">
                          Cleaning & Laundry
                        </option>
                        <option value="Service Center">Service Center</option>
                        <option value="Other">Other</option>
                      </select>
                      {/* <img
                        className="dropwdown_icon"
                        width={9}
                        height={8}
                        loading="lazy"
                        alt="dropdown icon"
                        src={dropddownicon}
                      /> */}
                      <div className="error">{formError.category}</div>
                    </div>
                    {/* <div className="material-textfield">
                      <input
                        placeholder=" "
                        type="text"
                        name="details"
                        value={values.details}
                        onChange={handleChange}
                      />
                      <label>Enquiry Details*</label>
                      <div className="error">{formError.details}</div>
                    </div> */}
                    <div className="material-textfield">
                      <textarea
                        rows="4"
                        placeholder="Enquiry Details*"
                        type="text"
                        name="details"
                        value={values.details}
                        onChange={handleChange}
                        className="enquiry_text"
                      ></textarea>
                      {/* <label className="enquiry_text_label">Enquiry Details*</label> */}
                      <div className="error">{formError.details}</div>
                    </div>
                    <div className="check_box">
                      <input
                        type="checkbox"
                        checked={values.consent}
                        onChange={handleChange}
                        name="consent"
                        className="check_box_selector"
                      />
                      <label className="check_box_label">
                        By checking the box, I agree to receive newsletters by
                        emails
                      </label>
                    </div>
                    <div className="cta_wrapper">
                      {/* <button className="send">
                        Send
                        <img src={arrowright} alt="caterina right img" />
                      </button> */}

                      <button className="send" type="submit">
                        {loading ? "Sending.." : "Send"}
                        <img src={arrowright} alt="caterina right img" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-5 left_section order-lg-1">
              <div className="background_img">
                {width > 850 ? (
                  <img src={contactusleft} alt="background img" />
                ) : null}
                {width > 767 === width < 850 ? (
                  <img src={contactusleftipad} alt="background img" />
                ) : null}
                {width < 767 ? (
                  <img src={contactusleftmob} alt="background img" />
                ) : null}
              </div>
              {/* <div className="text_container">
                <div className="icon_desc">
                  <img src={phone} className="phone_icon" alt="phone icon" />
                  <div className="phone_box">
                    <p className="phone_desc">
                      Call Us : <a href="tel:+254 709603001">+254 709603001</a>
                      <br />
                      Whatsapp Us :{" "}
                      <a href="tel:+254 707884461">+254 707884461</a>
                      <br />
                      Service number :{" "}
                      <a href="tel: 0800723723">
                        0800723723 , press 2 (toll free)
                      </a>
                    </p>
                  </div>
                </div>

                <div className="icon_desc">
                  <img src={location} className="phone_icon" alt="phone icon" />
                  <div className="phone_box">
                    <p className="phone_desc">
                      Ramtons Building, Mombasa Road Opposite City Cabanas,
                      Nairobi - Kenya
                    </p>
                  </div>
                </div>

                <div className="icon_desc">
                  <img src={mail} className="phone_icon" alt="phone icon" />
                  <div className="phone_box">
                    <p className="phone_desc">
                      <a href="mailto:info@hypermart.co.ke">
                        info@hypermart.co.ke
                      </a>
                    </p>
                  </div>
                </div>

                <div className="icon_desc m-0">
                  <img src={clock} className="phone_icon" alt="phone icon" />
                  <div className="phone_box">
                    <p className="phone_desc">
                      Monday - Friday 8 am - 4:30 pm <br />
                      Saturday : 8 am - 12:30 pm
                      <br />
                      Sunday Closed <br />
                      Closed on Public Holidays
                    </p>
                  </div>
                </div>
              </div> */}

              <div className="text_container">
                <div className="top_section">
                  <h2 className="text_heading">Msa Road Showroom</h2>
                  <div className="icon_desc1">
                    <img src={phone} className="phone_icon" alt="phone icon" />
                    <div className="phone_box">
                      <p className="phone_desc">
                        Call Us : <a href="tel:+254709603001">+254709603001</a>
                        <br />
                        Whatsapp Us :{" "}
                        <a
                          href="https://wa.me/+254707884461"
                          target="_blank"
                          rel="noreferrer"
                        >
                          +254707884461
                        </a>
                      </p>
                    </div>
                  </div>

                  <div className="icon_desc1">
                    <img src={mail} className="phone_icon" alt="phone icon" />
                    <div className="phone_box">
                      <p className="phone_desc">
                        <a href="mailto:Info@hypermart.co.ke">
                          Info@hypermart.co.ke
                        </a>
                      </p>
                    </div>
                  </div>

                  <div className="icon_desc1">
                    <img
                      src={location}
                      className="phone_icon"
                      alt="phone icon"
                    />
                    <div className="phone_box">
                      <p className="phone_desc">
                        <a
                          href="https://goo.gl/maps/sZNxxFBm3FTSUNSF8"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Ramtons Building, Mombasa Road (opposite City
                          Cabanas), Nairobi
                        </a>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="top_section">
                  <h2 className="text_heading">Ramtons Muthithi Road</h2>
                  <div className="icon_desc1">
                    <img src={phone} className="phone_icon" alt="phone icon" />
                    <div className="phone_box">
                      <p className="phone_desc">
                        Call Us : <a href="tel:+254709603200">+254709603200</a>
                      </p>
                    </div>
                  </div>
                  <div className="icon_desc1">
                    <img src={mail} className="phone_icon" alt="phone icon" />
                    <div className="phone_box">
                      <p className="phone_desc">
                        <a href="mailto:muthithi@hypermart.co.ke ">
                          muthithi@hypermart.co.ke 
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="icon_desc1">
                    <img
                      src={location}
                      className="phone_icon"
                      alt="phone icon"
                    />
                    <div className="phone_box">
                      <p className="phone_desc">
                        <a
                          href="https://g.co/kgs/fXqMMtM"
                          target="_blank"
                          rel="noreferrer"
                        >
                          64 Muthithi Rd, Nairobi, Kenya
                        </a>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="bottom_section">
                  <h2 className="text_heading">Kaysalt Showroom</h2>
                  <div className="icon_desc1">
                    <img src={phone} className="phone_icon" alt="phone icon" />
                    <div className="phone_box">
                      <p className="phone_desc">
                        Call Us : <a href="tel:0709603100">0709603100</a>
                      </p>
                    </div>
                  </div>

                  <div className="icon_desc1">
                    <img src={mail} className="phone_icon" alt="phone icon" />
                    <div className="phone_box">
                      <p className="phone_desc">
                        <a href="mailto:ks@hypermart.co.ke">
                          ks@hypermart.co.ke
                        </a>
                      </p>
                    </div>
                  </div>

                  <div className="icon_desc1">
                    <img
                      src={location}
                      className="phone_icon"
                      alt="phone icon"
                    />
                    <div className="phone_box">
                      <p className="phone_desc">
                        <a
                          // href="https://goo.gl/maps/x5eH2a9gfxA2GCm3A"
                          href="https://www.google.com/maps/place/Ramtons+Kaysalt+Showroom/@-1.3373776,36.8932451,15z/data=!4m6!3m5!1s0x182f0d9fa6052b17:0x6c72a0121465ba74!8m2!3d-1.3373776!4d36.8932451!16s%2Fg%2F11tsm_ybwl?entry=ttu"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Kaysalt Industrial Park, Mombasa Road, Nairobi
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contactus_sec2" data-aos="fade-up">
        <h2 className="map_head">Find Us</h2>
        {width > 767 ? (
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15954.953413857522!2d36.887446!3d-1.333141!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe2eb72f460833bb4!2sCaterina%20Commercial%20Appliances%20(Hypermart%20LTD)!5e0!3m2!1sen!2sin!4v1647267984293!5m2!1sen!2sin"
            width="100%"
            height="641"
            title="desktopmap"
            style={{ border: "0", margin: "0", padding: "0" }}
            loading="lazy"
          ></iframe>
        ) : (
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15954.953413857522!2d36.887446!3d-1.333141!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe2eb72f460833bb4!2sCaterina%20Commercial%20Appliances%20(Hypermart%20LTD)!5e0!3m2!1sen!2sin!4v1647267984293!5m2!1sen!2sin"
            width="100%"
            height="360"
            title="mobilemap"
            style={{ border: "0", margin: "0", padding: "0" }}
            loading="lazy"
          ></iframe>
        )}
      </section>
      <Sticky />
    </>
  );
};

export default ContactUs;
