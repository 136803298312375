import React, { useState } from "react";
import "./Newsletter.scss";
import { arrowright, scrollup } from "../../images";
import http from "../../helpers/http";
import config from "../../config/config";
import Joi from "joi";
import { useHistory } from "react-router-dom";

const Newsletter = (props) => {
  const scrollHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  let history = useHistory();

  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  let base_url = config.api_url + "forms/footer";

  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();
    console.log(values);
    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    const { data } = await http.post(base_url, values);

    if (data) {
      // redirect
      // navigate("/thank-you");
      history.push("/thank-you");
    }
    setValues({ email: "" });
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  return (
    <>
      <div className="newsletter">
        <img
          className="uparrow_img"
          src={scrollup}
          alt="caterina arrow"
          loading="lazy"
          onClick={scrollHandler}
        />
        <h2 className="news_heading">
          Subscribe for Members <br />
          to Receive Latest News and Special Offers
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="text">
            <input
              type="text"
              placeholder="Our Email ID"
              name="email"
              value={values.email}
              onChange={handleChange}
            />
            <button type="submit">
              {loading ? (
                <div className="spinner-grow text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <img
                  className="arrow_right"
                  alt="caterina arrow"
                  src={arrowright}
                />
              )}
            </button>
          </div>
          <div className="error email_error">{formError.email}</div>
        </form>

        <div className="news_check_box">
          <input type="checkbox" checked className="check_box_selector" />
          <label className="check_box_label">
          By checking the box, I agree to receive newsletters by emails
          </label>
        </div>
      </div>
    </>
  );
};

export default Newsletter;
