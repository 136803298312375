import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./common.scss";
import DefaultLayout from "./DefaultLayout";
import AOS from "aos";
import "aos/dist/aos.css";
import MetaDecorator from "./components/MetaDecorator/MetaDecorator";

AOS.init({
  duration: 2500,
});
const App = () => {
  // useEffect(() => {
  //   window.history.scrollRestoration = "manual";
  // }, []);

  return (
    <>
      <MetaDecorator
        metaTitle="Caterina Commercial Cooking &amp; Cleaning Appliances Kenya"
        metaDesc="Caterina Commercial Cooking &amp; Cleaning Appliances Kenya"
        canonicalLink={window.location.href}
      />
      <Router>
        <Switch>
          <Route path="/" component={DefaultLayout} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
