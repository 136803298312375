import React from "react";
import { NavLink } from "react-router-dom";
import "./Button.scss";

export const Button = ({ internalLink, linkTo, orange, btnText, removebg }) => {
  if (internalLink) {
    return (
      <NavLink
        to={linkTo ? linkTo : "/"}
        className={`common_btn ${orange ? "orange_filling" : "white_filling"}`}
      >
        {btnText}
      </NavLink>
    );
  }
  return (
    <a
      href={linkTo ? linkTo : null}
      className={`common_btn ${orange ? "orange_filling" : "white_filling"} ${
        removebg ? "removebg" : null
      }`}
    >
      {btnText}
    </a>
  );
};
