import React, { useEffect, useState } from "react";
import { Button } from "../../components/Button/Button";
import Counteritem from "../../components/CounterItem/CounterItem";
import { Link } from "react-router-dom";
import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
} from "swiper";
import "swiper/swiper-bundle.css";
import FsLightbox from "fslightbox-react";

import "swiper/swiper-bundle.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  homebacknew,
  explorelogo,
  fb,
  sec2,
  sec2back,
  arrowright,
  sec3,
  insta,
  homemob,
  navrightwhite,
  navleftwhite,
  fourBrand,
  zucchiniBrand,
  naivasBrand,
  radissonBrand,
  navrightRed,
  navleftRed,
} from "../../images";
import "./Homepage.scss";
import config from "../../config/config";
import http from "../../helpers/http";
import Loader from "../../components/Loader/Loader";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";
import styled, { keyframes } from "styled-components";
import Sticky from "../../components/Sticky/Sticky";

Swiper.use([
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
]);

const spin = keyframes`
0% { transform: translateX(0); }
100% { transform: translateX(calc(-15em * 7))}
`;

const SliderWrap = styled.div`
  background: transparent;
  height: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
`;
const SliderFlex = styled.div`
  background: transparent;
  animation-name: ${spin};
  animation-duration: 40s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  display: flex;
  width: calc(17em * 14});
`;

const Homepage = () => {
  const { width } = useWindowDimensions();
  const [toggler, setToggler] = useState(false);
  const [vidLink, setVidLink] = useState("");
  const [categoryTabIndex, setCategoryTabIndex] = useState(0);
  const [featuredproducts, setfeaturedproducts] = useState([]);
  const [brands, setbrands] = useState([]);
  const [loading, setloading] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const product_image_url = config.image_url + config.image_path.product; //with trailing slash
  const brand_image_url = config.image_url + config.image_path.brand; //with trailing slash

  const bannerArr = [
    {
      id: 1,
      heading: "Commercial catering ",
      heading2: "& cleaning equipment",
      desc: "Caterina has supplied the Horeca (hotels, restaurants and cafes) industry for almost two decades.",
    },
  ];

  useEffect(() => {
    var productswiper = new Swiper(".swiper-container.productswiper", {
      navigation: {
        nextEl: `.rightarrowimg${categoryTabIndex}`,
        prevEl: `.leftarrowimg${categoryTabIndex}`,
      },
      breakpoints: {
        300: {
          slidesPerView: 1.1,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 1.8,
        },
        992: {
          slidesPerView: 2.7,
          spaceBetween: 70,
        },
        1400: {
          slidesPerView: 2.7,
          spaceBetween: 80,
        },
        1600: {
          slidesPerView: 2.7,
          spaceBetween: 100,
        },
      },
    });
    var brand = new Swiper(".swiper-container.brand_slider", {
      slidesPerView: "auto",
      loop: true,
      freeMode: true,
      speed: 4000,
      grabCursor: false,
      allowTouchMove: false,
      autoplay: {
        delay: 1,
        disableOnInteraction: false,
      },
      spaceBetween: 50,
      breakpoints: {
        300: {
          spaceBetween: 20,
          slidesPerView: 2,
        },
        600: {
          slidesPerView: 2.6,
        },
        992: {
          slidesPerView: 3.3,
        },
        1921: {
          slidesPerView: 4.5,
        },
      },
    });
  }, [categoryTabIndex, featuredproducts, bannerArr]);

  const bannerList = bannerArr.map((item, i) => (
    <React.Fragment key={i}>
      {width > 834 ? (
        <div className="text_container">
          <h1 className="first_heading">
            {item.heading} {item.heading2}
          </h1>
          <div className="first_desc">{item.desc}</div>
        </div>
      ) : (
        <div>
          <h1 className="first_heading">
            {item.heading}
            <br />
            {item.heading2}
          </h1>
          <div className="first_desc">{item.desc}</div>
        </div>
      )}
    </React.Fragment>
  ));

  const brandList = brands.length
    ? brands.map((brands, i) => (
        <div className="swiper_box" key={i}>
          <img
            src={brand_image_url + brands.image}
            className="brand_img"
            alt="Caterina brand img"
          />
          <h1 className="brand_name">{brands.name}</h1>
        </div>
      ))
    : null;

  const categoryList = featuredproducts.map((item, i) => (
    <span
      key={i}
      className={`category_btn ${categoryTabIndex === i ? "active" : ""}`}
      onClick={() => setCategoryTabIndex(i)}
      Link
    >
      {item.name}
    </span>
  ));

  const tabdescList = featuredproducts?.map((item, i) => (
    <>
      <div
        className={`leftbox_wrap ${
          parseInt(categoryTabIndex) === i ? "active" : ""
        }`}
      >
        <p className="tab_desc">
          {categoryTabIndex === i ? item.description : ""}
        </p>
        {categoryTabIndex === i ? (
          <div className="cta_wrapper">
            <Link to={"/our-products/" + item?.slug}>
              <button className="readmore">
                Read More
                <img
                  src={arrowright}
                  alt="Caterina right arrow"
                  loading="lazy"
                />
              </button>
            </Link>
          </div>
        ) : (
          ""
        )}
        {width > 767 && categoryTabIndex === i ? (
          <div className="product_nav arrows_wrapper">
            <img
              src={navleftwhite}
              alt="Caterina left arrow logo"
              loading="lazy"
              className={`leftarrowimg leftarrowimg${categoryTabIndex}`}
              onMouseOver={(e) => (e.currentTarget.src = navleftRed)}
              onMouseOut={(e) => (e.currentTarget.src = navleftwhite)}
            />
            <img
              src={navrightwhite}
              alt="Caterina right arrow logo"
              loading="lazy"
              className={`rightarrowimg rightarrowimg${categoryTabIndex}`}
              onMouseOver={(e) => (e.currentTarget.src = navrightRed)}
              onMouseOut={(e) => (e.currentTarget.src = navrightwhite)}
            />
          </div>
        ) : null}
      </div>
    </>
  ));

  const ourBrandsSwiperList = featuredproducts?.map((brandData, i) => (
    <div
      className={`swiper-container productswiper ${
        parseInt(categoryTabIndex) === i ? "active" : ""
      }`}
      key={i}
    >
      <div className="swiper-wrapper">
        {brandData.products.map((item, i) => (
          <div
            // onClick={() => setProductIndex(i)}
            className={`swiper-slide product_details_container ${
              categoryTabIndex === i ? "active" : ""
            } `}
            key={i}
          >
            <div className="product_detail_wrapper">
              <Link
                to={`/our-products/${featuredproducts[categoryTabIndex]?.slug}/${item.slug}`}
              >
                <div className="imageanimate zoom-out">
                  <img
                    src={product_image_url + item.thumbnail}
                    alt="caterina our product img"
                    className="product_img"
                    loading="lazy"
                  />
                  <div className="content-overlay"></div>
                </div>
                <div className="text_container">
                  <h2 className="product_name">{item?.name?.toLowerCase()}</h2>
                  <p
                    className="product_synopsis"
                    dangerouslySetInnerHTML={{
                      __html: item.description.replace(
                        /(<? *script)/gi,
                        "illegalscript"
                      ),
                    }}
                  ></p>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  ));

  const handleImageWrapperClick = (videoLink) => {
    setToggler(!toggler);
    setVidLink(videoLink);
  };

  const fetchfeaturedproducts = async () => {
    let url = `${config.api_url}products/featured`;

    const { data } = await http.get(url);
    if (data) {
      setfeaturedproducts(data);
    }
  };

  const fetchbrands = async () => {
    let url = `${config.api_url}brands`;

    const { data } = await http.get(url);
    if (data) {
      setbrands(data);
    }
  };

  useEffect(() => {
    // if (!location.hash) {
    console.log("scrolled to top");
    window.scrollTo(0, 0);
    // }
  }, []);

  useEffect(() => {
    setloading(true);
    fetchfeaturedproducts();
    fetchbrands();
    setloading(false);
  }, []);

  const imageLoaded = (e) => {
    if (e.target.complete && e.target.naturalHeight !== 0) {
      setIsLoader(false);
    }
  };

  if (loading) {
    return "Loading";
  }

  return (
    <>
      <MetaDecorator
        metaTitle="Commercial Catering And Cleaning Equipments in Kenya | Caterina"
        metaDesc="Discover Caterina for an extensive selection of top-quality commercial catering and cleaning equipment in Kenya. Elevate your business today. Contact us now!"
        canonicalLink={window.location.href}
      />
      <div style={{ display: isLoader ? "block" : "none" }}>
        <Loader />
      </div>

      {width > 834 ? (
        <>
          <section
            className="home_sec1"
            data-aos-duration="500"
            data-aos="fade"
          >
            <div className="wrapper">
              <div className="row">
                <div className="my_container">
                  <div className="col-lg-5 first_container">
                    {bannerList}
                    <img
                      loading="lazy"
                      alt="homepage background img"
                      src={homebacknew}
                      style={{ display: "none" }}
                      onLoad={homebacknew ? imageLoaded : null}
                    />
                    {/* <div>{dotsList}</div> */}
                    <div className="cta_wrapper">
                      <Button
                        linkTo="/contact-us"
                        orange
                        btnText="Connect With Us"
                      />
                    </div>
                    <div className="social_icon">
                      <a
                        className="fb_icon"
                        href="https://www.facebook.com/caterinacommercialappliances/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={fb} alt="caterina fb img" loading="lazy" />
                      </a>
                      {/* <a
                      className="insta_icon"
                      href="https://www.instagram.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={insta}
                        alt="caterina insta img"
                        loading="lazy"
                      />
                    </a> */}
                    </div>
                  </div>
                  <div className="col-lg-7 second_container"></div>
                </div>
              </div>
              {/* <div className="explore_wrapper">
                <Link to="/contact-us">
                  <img
                    src={explorelogo}
                    alt="Caterina explore logo img"
                    // loading="lazy"
                    className="explorelogo"
                  />
                </Link>
              </div> */}
            </div>
          </section>
          {/* <div className="explore_wrapper">
            <Link to="/contact-us">
              <img
                src={explorelogo}
                alt="Caterina explore logo img"
                loading="lazy"
                className="explorelogo"
              />
            </Link>
          </div> */}
        </>
      ) : (
        <>
          <section className="home_sec1_mob" data-aos="fade">
            <div className="wrapper">
              <div className="first_container_mob">
                {bannerList}
                <div className="cta_wrapper">
                  <Button
                    linkTo="/contact-us"
                    orange
                    btnText="Connect With Us"
                  />
                </div>
                {/* <div>{dotsList}</div> */}
              </div>
              <div className="second_container_mob">
                <img
                  className="homepage_mobimg"
                  src={homemob}
                  alt="caterina img"
                />
              </div>
              {/* <div className="explore_wrapper">
              <Link to="/contact-us">
                <img
                  src={explorelogo}
                  alt="Caterina explore logo img"
                  loading="lazy"
                  className="explorelogo"
                />
              </Link>
            </div> */}
            </div>
          </section>
          {/* <div className="explore_wrapper">
            <Link to="/contact-us">
              <img
                src={explorelogo}
                alt="Caterina explore logo img"
                loading="lazy"
                className="explorelogo"
              />
            </Link>
          </div> */}
        </>
      )}

      <section className="home_sec5" data-aos="fade-up">
        <div className="my_container">
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="description_box">
                <h2 className="brand_intro">
                  More <p className="brand_intro_orange">Brands That</p> Have
                  Worked with Us
                </h2>
                <p className="brand_desc">
                  We have worked with some of the best in the industry
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-8 brand_swiper_wrapper">
              {/* <div className="swiper-container brand_slider">
                <div className="swiper-wrapper">{brandList}</div>
              </div> */}
              <SliderWrap className="slider_wrap">
                {/* <SliderFlex>{brandList}</SliderFlex> */}
                <SliderFlex>
                  <div className="swiper_box">
                    <img
                      src={fourBrand}
                      className="brand_img"
                      alt="Caterina brand img"
                    />
                    <h3 className="brand_name">Four Points By Sheraton</h3>
                  </div>
                  <div className="swiper_box">
                    <img
                      src={zucchiniBrand}
                      className="brand_img"
                      alt="Caterina brand img"
                    />
                    <h3 className="brand_name">Zucchini</h3>
                  </div>
                  <div className="swiper_box">
                    <img
                      src={naivasBrand}
                      className="brand_img"
                      alt="Caterina brand img"
                    />
                    <h3 className="brand_name">Naivas</h3>
                  </div>
                  <div className="swiper_box">
                    <img
                      src={radissonBrand}
                      className="brand_img"
                      alt="Caterina brand img"
                    />
                    <h3 className="brand_name">Radisson Blu</h3>
                  </div>
                  <div className="swiper_box">
                    <img
                      src={fourBrand}
                      className="brand_img"
                      alt="Caterina brand img"
                    />
                    <h3 className="brand_name">Four Points By Sheraton</h3>
                  </div>
                  <div className="swiper_box">
                    <img
                      src={zucchiniBrand}
                      className="brand_img"
                      alt="Caterina brand img"
                    />
                    <h3 className="brand_name">Zucchini</h3>
                  </div>
                  <div className="swiper_box">
                    <img
                      src={naivasBrand}
                      className="brand_img"
                      alt="Caterina brand img"
                    />
                    <h3 className="brand_name">Naivas</h3>
                  </div>
                  <div className="swiper_box">
                    <img
                      src={radissonBrand}
                      className="brand_img"
                      alt="Caterina brand img"
                    />
                    <h3 className="brand_name">Radisson Blu</h3>
                  </div>
                  <div className="swiper_box">
                    <img
                      src={fourBrand}
                      className="brand_img"
                      alt="Caterina brand img"
                    />
                    <h3 className="brand_name">Four Points By Sheraton</h3>
                  </div>
                  <div className="swiper_box">
                    <img
                      src={zucchiniBrand}
                      className="brand_img"
                      alt="Caterina brand img"
                    />
                    <h3 className="brand_name">Zucchini</h3>
                  </div>
                  <div className="swiper_box">
                    <img
                      src={naivasBrand}
                      className="brand_img"
                      alt="Caterina brand img"
                    />
                    <h3 className="brand_name">Naivas</h3>
                  </div>
                  <div className="swiper_box">
                    <img
                      src={radissonBrand}
                      className="brand_img"
                      alt="Caterina brand img"
                    />
                    <h3 className="brand_name">Radisson Blu</h3>
                  </div>
                  <div className="swiper_box">
                    <img
                      src={naivasBrand}
                      className="brand_img"
                      alt="Caterina brand img"
                    />
                    <h3 className="brand_name">Naivas</h3>
                  </div>
                  <div className="swiper_box">
                    <img
                      src={radissonBrand}
                      className="brand_img"
                      alt="Caterina brand img"
                    />
                    <h3 className="brand_name">Radisson Blu</h3>
                  </div>
                </SliderFlex>
              </SliderWrap>
            </div>
          </div>
        </div>
      </section>

      <section className="home_sec2" data-aos="fade-up">
        <div className="my_container wrapper">
          <div className="row">
            <div className="col-lg-8">
              <h2 className="hollow_title">About Us</h2>
              <div className="description_box">
                {width > 767 ? (
                  <h2 className="aboutus_intro">
                    Quality and service
                    <p className="aboutus_intro_orange"> since 2005</p>
                  </h2>
                ) : (
                  <h2 className="aboutus_intro">
                    Quality and service
                    <p className="aboutus_intro_orange"> since 2005</p>
                  </h2>
                )}
                <p className="aboutus_desc">
                  Started in 2005, Caterina has earned a reputation for offering
                  quality, long-lasting equipment that provides the necessary
                  durability to withstand heavy commercial use. With expert
                  after-sales service from Ramtons and locally available spare
                  parts, you can count on us.
                </p>
              </div>
              {width > 767 ? (
                <>
                  <div className="cta_wrapper">
                    <Link to="/about-us" className="readmore">
                      Read More
                      <img
                        src={arrowright}
                        alt="caterina right img"
                        loading="lazy"
                      />
                    </Link>
                  </div>
                </>
              ) : null}
            </div>
            <div className="col-lg-4">
              <img
                className="back_img"
                src={sec2back}
                alt="caterina back img"
              />
              <div className="imageanimate">
                <img
                  className="aboutus_img"
                  src={sec2}
                  alt="caterina aboutus img"
                />
              </div>
              {/* <img
                className="video_icon"
                src={video}
                alt="caterina video icon img"
                onClick={() =>
                  handleImageWrapperClick(
                    "https://www.youtube.com/embed/HHjSdy9l7Kc"
                  )
                }
              /> */}
              {/* <p className="video_title">
                Watch <br />
                Video Tour
              </p> */}
            </div>
          </div>
          {width < 767 ? (
            <>
              <div className="cta_wrapper">
                <Link to="/about-us" className="readmore">
                  Read More
                  <img
                    src={arrowright}
                    alt="caterina right img"
                    loading="lazy"
                  />
                </Link>
              </div>
            </>
          ) : null}
        </div>
      </section>

      <section className="home_sec3" data-aos="fade-up">
        {width > 767 ? <h2 className="hollow_title">Products</h2> : null}
        <div className="product_container">
          <div className="ourproduct_box">
            <h2 className="ourpro_heading">Our Products</h2>
            <p className="ourpro_desc">
              We offer an extensive range of products, From commercial ovens and
              mixers to under-counter chillers to coffee makers to salamanders.
              Download our catalogue, give us a call or visit our showroom to
              explore our product range.
            </p>
            {width > 767 ? (
              <div className="cta_wrapper">
                <Link
                  to={`/our-products/${featuredproducts[0]?.slug}`}
                  className="readmore"
                >
                  Read More
                  <img
                    src={arrowright}
                    alt="caterina right img"
                    loading="lazy"
                  />
                </Link>
              </div>
            ) : null}
          </div>
          <div className="category_btns_wrapper">{categoryList}</div>
          <div className="row products_slide_wrapper">
            <div className="col-md-5 col-lg-3 p-0 desc_wrapper">
              {tabdescList}
            </div>
            <div className="col-md-8 col-lg-9 productswiper_2">
              {ourBrandsSwiperList}
              {/* <div className="swiper-container productswiper">
                    <div className="swiper-wrapper" id="gallery_wrapper">
                      {productsList}
                    </div>
                  </div> */}
              {width < 767 ? (
                <>
                  <div className="product_nav arrows_wrapper">
                    <img
                      src={navleftwhite}
                      alt="Caterina left arrow logo"
                      loading="lazy"
                      className={`leftarrowimg leftarrowimg${categoryTabIndex}`}
                      onMouseOver={(e) => (e.currentTarget.src = navleftRed)}
                      onMouseOut={(e) => (e.currentTarget.src = navleftwhite)}
                    />
                    <img
                      src={navrightwhite}
                      alt="Caterina right arrow logo"
                      loading="lazy"
                      className={`rightarrowimg rightarrowimg${categoryTabIndex}`}
                      onMouseOver={(e) => (e.currentTarget.src = navrightRed)}
                      onMouseOut={(e) => (e.currentTarget.src = navrightwhite)}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
        {width < 767 ? <h2 className="hollow_title">Products</h2> : null}
      </section>

      <section className="home_sec4" data-aos="fade-up">
        <div className="my_container">
          <div className="row">
            {width < 767 ? (
              <>
                <div className="description_box_mob">
                  <h2 className="howare_intro_mob">
                    {/* Creative{" "} */}
                    {/* <p className="howare_intro_orange_mob">Solutions By</p>{" "} */}
                    {/* Professional Designers */}
                    Quality{" "}
                    <p className="howare_intro_orange_mob">
                      commercial equipment
                    </p>{" "}
                    that offers great value
                  </h2>
                  <p className="howare_desc_mob">
                    Caterina was launched by Hypermart Ltd. to supply quality
                    commercial catering and cleaning equipment that provides the
                    necessary durability to withstand heavy commercial use
                  </p>
                </div>
              </>
            ) : null}
            <div className="col-lg-5">
              <img
                className="sec4_back"
                src={sec2back}
                alt="caterina back img"
                loading="lazy"
                onLoad={sec2back ? imageLoaded : null}
              />
              <div className="imageanimate">
                <img
                  className="how_img"
                  src={sec3}
                  alt="caterina howsec img"
                  loading="lazy"
                  onLoad={sec3 ? imageLoaded : null}
                />
              </div>
            </div>
            <div className="col-lg-7">
              {width > 767 ? (
                <>
                  <h2 className="hollow_title why_text">Why us!</h2>
                  <div className="description_box">
                    <h2 className="howare_intro">
                      {/* Creative{" "} */}
                      {/* <p className="howare_intro_orange">Solutions By </p> */}
                      {/* Professional */}
                      {/* <br /> Designers */}
                      Quality{" "}
                      <p className="howare_intro_orange">
                        commercial equipment{" "}
                      </p>
                      that offers great value
                    </h2>
                    <p className="howare_desc">
                      Caterina was launched by Hypermart Ltd. to supply quality
                      commercial catering and cleaning equipment that provides
                      the necessary durability to withstand heavy commercial use
                    </p>
                  </div>
                </>
              ) : null}

              <div className="counter_wrapper_flex">
                <div className="counter_cont">
                  <Counteritem
                    startCount={2000}
                    endCount={2005}
                    duration={5}
                    countTitle="Established in 2005, Caterina has a track record of serving our customers."
                    // plus
                    // aboveThousand
                  />
                </div>
                <div className="counter_cont">
                  <Counteritem
                    endCount={100}
                    duration={5}
                    countTitle="With over 100 items to choose from, we cater to your taste."
                    plus
                  />
                </div>
                <div className="counter_cont">
                  <Counteritem
                    endCount={10}
                    duration={5}
                    countTitle="with over 10 after-sales technicians, we provide excellent after-sales service."
                    plus
                  />
                </div>
                <div className="counter_cont">
                  <Counteritem
                    endCount={492}
                    duration={5}
                    // countTitle="Caterina is a brand of commercial kitchen and cleaning appliances that."
                    countTitle="100+ our products are found in over 100 outlets countrywide"
                    plus
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="home_sec5" data-aos="fade-up">
        <div className="my_container">
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="description_box">
                <h2 className="brand_intro">
                  More <p className="brand_intro_orange">Brands That</p> Have
                  Work with Us
                </h2>
                <p className="brand_desc">
                  We have worked with some of the best in the industry
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-8 brand_swiper_wrapper">
              <div className="swiper-container brand_slider">
                <div className="swiper-wrapper">{brandList}</div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Sticky />
      <FsLightbox toggler={toggler} sources={[vidLink]} />
    </>
  );
};

export default Homepage;
