import React from "react";
import { loader } from "../../images";

const Loader = () => {
  return (
    <div className="loader_wrapper">
      <img
        src={loader}
        alt="Caterina loader"
        loading="lazy"
        className="loader_gif"
      />
    </div>
  );
};

export default Loader;
