import React, { useEffect, useState } from "react";
import { Banner } from "../../components/Banner/Banner";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

import { Link } from "react-router-dom";
import Form from "./Form";
import {
  leftarrow,
  rightarrow,
  navleft,
  arrowright,
  navleftblack,
  navrightblack,
  navrightRed,
  navleftRed,
} from "../../images";
import "./ProductInner.scss";
import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
} from "swiper";
import "swiper/swiper-bundle.css";
import config from "../../config/config";
import http from "../../helpers/http";
import Sticky from "../../components/Sticky/Sticky";
Swiper.use([
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
]);

const ProductInner = (props) => {
  const [data, setData] = useState({});
  const [loading, setloading] = useState(false);
  const [over, setOver] = useState("");

  const image_url = config.image_url + config.image_path.product; //with trailing slash

  const scrollToForm = () => {
    const id = "proinner_form";
    const yOffset = -250;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  useEffect(() => {
    const sliderThumbs = new Swiper(".slider__thumbs .swiper-container", {
      direction: "vertical",
      slidesPerView: 4,
      spaceBetween: 24,
      allowTouchMove: false,
      navigation: {
        nextEl: ".slider__next",
        prevEl: ".slider__prev",
      },
      breakpoints: {
        0: {
          spaceBetween: 10,
          slidesPerView: 2.8,
          direction: "horizontal",
        },
        767: {
          direction: "vertical",
        },
      },
    });
    const sliderImages = new Swiper(".main_img_wrap .swiper-container", {
      slidesPerView: 1,
      spaceBetween: 20,
      watchOverflow: true,
      // freeMode: true,
      allowTouchMove: false,
      thumbs: {
        swiper: sliderThumbs,
      },
      navigation: {
        nextEl: ".slider__next",
        prevEl: ".slider__prev",
      },

      breakpoints: {
        0: {
          direction: "horizontal",
        },
        768: {
          direction: "vertical",
        },
      },
    });

    const moreProduct = new Swiper(".moreproduct_list .swiper-container", {
      spaceBetween: 32,
      direction: "horizontal",
      navigation: {
        nextEl: ".product__next",
        prevEl: ".product__prev",
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          slidesPerColumn: 2,
          slidesPerColumnFill: "row",
        },
        768: {
          slidesPerView: 2,
        },
        1023: {
          slidesPerView: 4,
        },
      },
    });
  });

  const fetchData = async (slug) => {
    let url = `${config.api_url}products/${slug}`;

    const { data } = await http.get(url);
    if (data) {
      setData(data);
    }
  };

  useEffect(() => {
    setloading(true);
    fetchData(props.match.params.product);

    setloading(false);
    window.scrollTo(0, 0);
  }, [props.match.params.product]);

  const moreProduct = [
    {
      heading: "More Products",
    },
  ];

  const headingList = moreProduct.map((name, i) => (
    <React.Fragment key={i}>
      <h2 className="morepro_heading">{name.heading}</h2>
    </React.Fragment>
  ));

  const moreProductList = data?.related?.length ? (
    data?.related?.map((item, i) => (
      <div className="product_details_container swiper-slide" key={i}>
        <div className="product_detail_wrapper">
          <Link
            to={`/our-products/${props.match.params.category}/${item.slug}`}
          >
            <div className="imageanimate zoom-out">
              <img
                src={image_url + item.thumbnail}
                alt="caterina our product img"
                loading="lazy"
                className="product_img"
              />
              <div className="content-overlay"></div>
            </div>
            <div className="text_container">
              <h3 className="product_name">{item?.name?.toLowerCase()}</h3>

              {/* <p
                className="product_synopsis"
                dangerouslySetInnerHTML={{
                  __html: reduceDescSize(item.description, 75).replace(
                    /(<? *script)/gi,
                    "illegalscript"
                  ),
                }}
              ></p> */}
              <div className="product_cta_wrapper">
                <div className="common_know_more">
                  Read more
                  <span className="arrow_image"></span>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    ))
  ) : (
    <h1>No Products Found</h1>
  );

  let thumsList = [];
  thumsList = data?.productGalleries?.length
    ? data?.productGalleries?.map((item, i) => (
        <div className="swiper-slide" key={i}>
          <div className="slider__image thumb_img_wrap">
            <img
              src={image_url + item.image}
              alt="caterina product img"
              className="thumb_img"
            />
          </div>
        </div>
      ))
    : [];

  thumsList?.unshift(
    <div className="swiper-slide">
      <div className="slider__image thumb_img_wrap">
        <img
          src={image_url + data?.thumbnail}
          alt="caterina product img"
          className="thumb_img"
        />
      </div>
    </div>
  );
  let productimgList = [];
  productimgList = data?.productGalleries?.length
    ? data?.productGalleries?.map((item, i) => (
        <div className="swiper-slide" key={i}>
          <div className="slider__image product_img_wrap">
            <img
              src={image_url + item.image}
              alt="caterina product img"
              className="product_img"
            />
          </div>
        </div>
      ))
    : [];

  productimgList?.unshift(
    <div className="swiper-slide">
      <div className="slider__image product_img_wrap">
        <img
          src={image_url + data?.thumbnail}
          alt="caterina product img"
          className="product_img"
        />
      </div>
    </div>
  );
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  if (loading) {
    return "Loading";
  }

  return (
    <>
      <MetaDecorator
        metaTitle={data?.meta_title ? data?.meta_title : data?.name}
        metaDesc={data?.meta_desc}
        canonicalLink={window.location.href}
      />
      <Banner
        imgSrc={image_url + data.banner}
        breadcrumbWord1="Home"
        breadcrumbWord3="Our Products"
        breadcrumbWord4={data.name}
        link3={`/our-products/${props.match.params.category}`}
        disabled
      />

      <section className="proinner_sec1" data-aos="fade-up">
        <div className="my_container">
          <div className="row">
            <div className="col-lg-7">
              <div className="swiper_wrapper">
                <div className="slider">
                  <div className="slider__flex">
                    <div className="slider__col">
                      <div className="slider__thumbs">
                        <div className="swiper-container">
                          <div className="swiper-wrapper">{thumsList}</div>
                        </div>
                      </div>
                    </div>
                    <div className="main_img_wrap">
                      <div className="swiper-container">
                        <div className="swiper-wrapper">{productimgList}</div>
                      </div>

                      <div className="swiper_navigations">
                        <div className="slider__prev">
                          <img
                            src={leftarrow}
                            className="nav_icons"
                            alt="caterina left arrow"
                          />
                        </div>
                        <div className="slider__next">
                          <img
                            src={rightarrow}
                            className="nav_icons"
                            alt="caterina right arrow"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="text_container">
                <h1 className="product_name">{data?.name?.toLowerCase()}</h1>
                <h2 className="product_name product_code">
                  Product Code: {data?.product_code}
                </h2>
                <ul
                  className="product_desc"
                  dangerouslySetInnerHTML={{
                    __html: data.description,
                  }}
                >
                  {/* {data.description} */}
                </ul>
                <div className="cta_wrapper">
                  <button className="enquire" onClick={scrollToForm}>
                    Enquire
                    <img src={arrowright} alt="caterina right img" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {moreProductList.length && (
        <section className="proinner_sec2" data-aos="fade-up">
          <div className="my_container">
            <div>{headingList}</div>
            <div className="moreproduct_list">
              <div className="swiper_navigations">
                <div className="product__prev">
                  <img
                    src={navleftblack}
                    className="nav_icons"
                    alt="caterina left arrow"
                    onMouseOver={(e) => (e.currentTarget.src = navleftRed)}
                    onMouseOut={(e) => (e.currentTarget.src = navleftblack)}
                  />
                </div>

                {/* <div className={`product__prev ${over == "navleftw" ? "active" : ""}`}> */}
                {/* <div className="product__prev">
                  <img
                    src={over == "navleftw" ? navleftRed : navleftblack}
                    className="nav_icons"
                    alt="caterina left arrow"
                    onMouseOver={() => setOver(over == "navleftw" ? "" : "navleftw")}
                    onMouseOut={() => setOver(over == "navleftw" ? "" : "navleftw")}
                  />
                </div> */}
                {/* <div className={`product__next ${over == "navright" ? "active" : ""}`} > */}
                {/* <div className="product__next">
                  <img
                    src={over == "navright" ? navrightRed : navrightblack}
                    className="nav_icons"
                    alt="caterina right arrow"
                    onMouseOver={() => setOver(over == "navright" ? "" : "navright")}
                    onMouseOut={() => setOver(over == "navright" ? "" : "navright")}
                  />
                </div> */}

                <div className="product__next">
                  <img
                    src={navrightblack}
                    className="nav_icons"
                    alt="caterina right arrow"
                    onMouseOver={(e) => (e.currentTarget.src = navrightRed)}
                    onMouseOut={(e) => (e.currentTarget.src = navrightblack)}
                  />
                </div>
              </div>
              <div className="swiper-container">
                <div className="swiper-wrapper">{moreProductList}</div>
              </div>
            </div>
          </div>
        </section>
      )}

      <Form product={data?.name} />
      <Sticky />
    </>
  );
};

export default ProductInner;
