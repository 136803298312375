import React, { useEffect } from "react";

import "./ThankYou.scss";
import { thank } from "../../images";
import { Link } from "react-router-dom";
import Sticky from "../../components/Sticky/Sticky";

const ThankYou = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="thankyousec">
        <div className="thankyou_container">
          <div className="headp">
            <img className="thankyouimg1" src={thank} alt="thankyou img" />
            <h1 className="hollow_title">Thankyou</h1>
            <h1 className="heading">Thankyou!</h1>
            <h1 className="heading2">Your Submission Has Been Received.</h1>
            <p>We Will Connect With You Shortly.</p>
            <p className="mobilep">
              We Will Connect With You Shortly.
            </p>
            <div>
              <p className="link_p">
                Please Return To The{" "}
                <Link className="orange_link" to="/">
                  Homepage
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Sticky />
    </>
  );
};

export default ThankYou;
